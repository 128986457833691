import rn from 'random'
import { repeat } from 'rambda'
import { shuffle, renderAnswer } from './utils'

const approxCheck = (real: number, result: number, precision: number) => {
  if (real === result) {
    return true
  }

  const ratio = result / real
  const diff = Math.abs(1 - ratio)
  return diff < precision
}

export const SimpleAddSubtract = ({
  count,
  range,
}: QuestionMakerParams): IQuestion[] => {
  const initQuestion = () => {
    const op = [-1, 1][rn.int(0, 1)]
    const x = rn.int(range[0] + 1, range[1])
    const y = rn.int(range[0], x - 1)
    const result = x + op * y
    const title = `${x} ${op === -1 ? '-' : '+'} ${y} = ?`

    const check = (answer: Answer) => {
      if (!answer) return false
      return parseInt(answer, 10) === result
    }

    const answer = renderAnswer(title)
    return { title, check, answer }
  }

  return repeat(initQuestion, count).map(init => init())
}

export const SimpleMultiplyDivide = ({
  count,
  range,
}: QuestionMakerParams): IQuestion[] => {
  const initQuestion = () => {
    const x = rn.int(range[0], range[1])
    const y = rn.int(2, Math.min(x, 10))
    const op = [-1, 1][rn.int(0, 1)]

    const preciseAnswer = x * Math.pow(y, op)
    const apprxAnswer = Math.round(preciseAnswer)

    const equal = preciseAnswer === apprxAnswer ? '=' : '≈'
    const title = `${x} ${op === -1 ? '/' : 'x'} ${y} ${equal} ?`

    const check = (answer: Answer) => {
      if (!answer) return false
      const parsed = parseInt(answer)
      if (parsed === preciseAnswer) return true
      return Math.round(preciseAnswer) === parsed
    }

    const answer = renderAnswer(title)
    return { title, check, answer }
  }
  return repeat(initQuestion, count).map(init => init())
}

export const LargeApproximateCalculation = ({
  count,
}: QuestionMakerParams): IQuestion[] => {
  const initQuestion = () => {
    const x = rn.int(1000, 10000)
    const y = rn.int(2, 20)
    const real = x / y
    const title = `${x} / ${y} ≈ ?`

    const check = (answer: Answer) => {
      if (!answer) return false
      return approxCheck(real, parseInt(answer, 10), 0.1)
    }

    const answer = renderAnswer(title)

    return { title, check, answer }
  }
  return repeat(initQuestion, count).map(init => init())
}

export const QuestionMap: QuestionClass[] = [
  {
    method: SimpleAddSubtract,
    params: {
      count: 40,
      range: [1, 20],
    },
    describe: 'Addition & subtraction within 0~40',
    time: 2.5,
  },
  {
    method: SimpleAddSubtract,
    params: {
      count: 40,
      range: [1, 50],
    },
    describe: 'Addition & subtraction within 0~140',
    time: 2.8,
  },
  {
    method: SimpleMultiplyDivide,
    params: {
      count: 40,
      range: [2, 20],
    },
    describe: 'Multiplication & division within 1~200',
    time: 3,
  },
  {
    method: p => {
      const count = p.count / 2
      const range = p.range
      const hard = SimpleMultiplyDivide({ count, range })
      const medium = SimpleAddSubtract({ count, range })
      return shuffle(hard.concat(medium))
    },
    params: {
      count: 40,
      range: [2, 50],
    },
    describe: 'Mix of calculations within 1~method',
    time: 3.3,
  },
  {
    method: LargeApproximateCalculation,
    params: {
      count: 20,
      range: [-1],
    },
    describe: 'Approximate calculation with large numbers',
    time: 8,
  },
]
