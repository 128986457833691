import { QuestionMap as SimpleProblems } from './question_bank'
import { QuestionMap as WordingProblems } from './question_2'
import { generateQuestions } from './utils'

export const CategoryValues: Category[] = ['calculation', 'modulo']

export const Categories: Record<Category, CategoryData> = {
  calculation: {
    label: 'Simple Calculation',
    value: 'calculation',
    data: generateQuestions(SimpleProblems),
    numberOfLevels: SimpleProblems.length,
  },
  modulo: {
    label: 'Modulo Problems',
    value: 'modulo',
    data: generateQuestions(WordingProblems),
    numberOfLevels: WordingProblems.length,
  },
}

export const sideMenu: MasterMenu = [
  {
    label: 'Practice Now',
    route: '/',
  },
  {
    label: 'Stats',
    route: '/stats',
  },
]
