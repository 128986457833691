import { Badge, Card, Input } from 'antd'
import { ChangeEventHandler, useState } from 'react'
import style from './Question.module.sass'

export type UIProps = {
  correct: boolean
  focus: boolean
  index: number
  title: string
  value: Answer
  onChange: ChangeEventHandler<HTMLInputElement>
  disabled: boolean
  onFocus: () => void
  onBlur: () => void
}

export const UI = (p: UIProps) => {
  const status = p.correct ? 'success' : 'warning'
  const cls =
    (
      ['correct', 'focus'] as Array<keyof Pick<UIProps, 'correct' | 'focus'>>
    ).find(s => p[s]) || 'default'
  return (
    <Badge status={status} count={p.index}>
      <Card title={p.title} className={style[cls]} bordered={false}>
        <Input
          bordered={false}
          value={p.value}
          onChange={p.onChange}
          disabled={p.disabled}
          onFocus={p.onFocus}
          onBlur={p.onBlur}
        />
      </Card>
    </Badge>
  )
}

export type Props = {
  question: IQuestion
  onAnswerCorrect: (answer: any) => void
  disabled?: boolean
  index: number
}

export function Question(p: Props) {
  const { onAnswerCorrect } = p
  const { title, answer, check } = p.question
  const [userAnswer, setUserAnser] = useState<Answer>(undefined)
  const [correct, setCorrect] = useState(false)
  const [isFocus, setIsFocus] = useState(false)

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value
    setUserAnser(value)

    if (check(value)) {
      setCorrect(true)
      onAnswerCorrect(value)
    }
  }

  const answerText = typeof answer === 'string' ? answer : answer(userAnswer)
  const cardTitle = correct ? answerText : title

  return (
    <UI
      value={userAnswer}
      index={p.index}
      correct={correct}
      title={cardTitle}
      focus={isFocus}
      disabled={p.disabled || correct}
      onChange={handleChange}
      onBlur={() => setIsFocus(false)}
      onFocus={() => setIsFocus(true)}
    />
  )
}
