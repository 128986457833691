import { Result, Button, Typography } from 'antd'

type StartProps = {
  onStart: () => void
}

type FinishProps = {
  score: number
  totalScore: number
  onRetake: () => void
}

export function Start(props: StartProps) {
  const subtitle = (
    <Typography.Text>
      Click on the button below to start taking Test
    </Typography.Text>
  )

  return (
    <Result
      title="Practice makes Greatness!"
      subTitle={subtitle}
      extra={[
        <Button type="primary" key="console" onClick={props.onStart}>
          Start Test
        </Button>,
      ]}
    />
  )
}

export function Finish(props: FinishProps) {
  const subtitle = (
    <Typography.Text>{`You scored:  ${props.score} / ${props.totalScore}`}</Typography.Text>
  )

  return (
    <Result
      status="success"
      title="Test is over. Here is your result"
      subTitle={subtitle}
      extra={[
        <Button type="primary" key="console" onClick={props.onRetake}>
          Retake the Test
        </Button>,
      ]}
    />
  )
}
