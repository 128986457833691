import { List } from 'antd'
import { useState } from 'react'
import { Question } from 'components'
import style from './QuestionList.module.sass'

export type Props = {
  onResultChange: (result: number) => void
  data: IQuestion[]
  questionPerRow?: number
}

export function QuestionList(props: Props) {
  const { onResultChange } = props
  const [result, setResult] = useState(0)

  const updateResult = () => {
    setResult(result + 1)
    onResultChange(result + 1)
  }

  return (
    <List
      className={style.default}
      grid={{ gutter: 16, column: props.questionPerRow || 3 }}
      dataSource={props.data}
      renderItem={(item, idx) => (
        <List.Item>
          <Question
            question={item}
            onAnswerCorrect={updateResult}
            index={idx + 1}
          />
        </List.Item>
      )}
    />
  )
}
