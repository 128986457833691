import { Layout, PageHeader, Menu } from 'antd'
import { PropsWithChildren, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
import style from './Sidebar.module.sass'

const { Sider, Content } = Layout

export type Props = {
  header: ReactNode
  menu: MasterMenu
}

export function Sidebar(props: PropsWithChildren<Props>) {
  const location = useLocation()
  return (
    <Sider className={style.default}>
      <PageHeader className={style.header}>{props.header}</PageHeader>
      <Menu
        mode="inline"
        className={style.menu}
        selectedKeys={[location.pathname]}
      >
        {props.menu.map(item => (
          <Menu.Item key={item.route}>
            <Link to={item.route}>{item.label}</Link>
          </Menu.Item>
        ))}
      </Menu>
      <Content>{props.children}</Content>
    </Sider>
  )
}
