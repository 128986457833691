import rn from 'random'
import { repeat } from 'rambda'
import { renderAnswer } from './utils'

const findModulo = (
  [x1, x2]: number[],
  [y1, y2]: number[],
  modifyX: (n: number) => number = x => x,
  modifyY: (n: number) => number = y => y
): IQuestion => {
  let x = modifyX(rn.int(x1, x2))
  let y = modifyY(rn.int(y1, y2))
  const result = x % y
  const title = `${x} % ${y} = ?`

  const check = (answer: Answer) => {
    if (!answer) return false
    return parseInt(answer, 10) === result
  }

  const answer = renderAnswer(title)
  return { title, check, answer }
}

export const ModuloQuestions = (params: QuestionMakerParams): IQuestion[] => {
  const xRanges = params.range.slice(0, 2)
  const yRanges = params.range.slice(2)
  const roundY = (y: number) => y + 5 - ((y + 5) % 5)
  return repeat(findModulo, params.count).map(q =>
    q(xRanges, yRanges, undefined, roundY)
  )
}

export const BigModuloQuestions = (
  params: QuestionMakerParams
): IQuestion[] => {
  const xRanges = params.range.slice(0, 2)
  const yRanges = params.range.slice(2)
  const roundX = (x: number) => x - (x % 10)
  const roundY = (y: number) => y - (y % 10)
  return repeat(findModulo, params.count).map(q =>
    q(xRanges, yRanges, roundX, roundY)
  )
}

export const QuestionMap: QuestionClass[] = [
  {
    method: ModuloQuestions,
    params: {
      count: 10,
      range: [20, 100, 1, 20],
    },
    describe: 'Find modulos',
    time: 4,
  },
  {
    method: ModuloQuestions,
    params: {
      count: 10,
      range: [50, 100, 10, 50],
    },
    describe: 'Find modulos',
    time: 5,
  },
  {
    method: BigModuloQuestions,
    params: {
      count: 10,
      range: [200, 1000, 10, 200],
    },
    describe: 'Find modulos',
    time: 5,
  },
]
