import { useCallback, useEffect, useState } from 'react'
import useCountDown from 'react-countdown-hook'
import { Progress } from 'antd'
import { formatTime } from 'libs/utils'
import style from './Timer.module.sass'

export type UIProps = {
  strTime: string
  progress: number
  disabled: boolean
  width?: number
}

export const UI = (p: UIProps) => {
  const cls = p.disabled ? 'disabled' : 'default'
  return (
    <div className={style[cls]}>
      <Progress
        type="circle"
        percent={p.progress}
        format={() => p.strTime}
        width={p.width || 150}
      />
    </div>
  )
}

export type TIMER_STATE = 'WAITING' | 'STARTED' | 'PAUSE' | 'FINISHED'

export function useTimer(limit: number, onExpire: () => void) {
  const [progress, setProgress] = useState(100)
  const [timerState, setTimerState] = useState<TIMER_STATE>('WAITING')
  const [timeLeft, { start, pause, reset }] = useCountDown(limit * 1000)

  useEffect(() => {
    if (timerState === 'STARTED') {
      const current = Math.round(timeLeft / 10 / limit)
      setProgress(current)
    }

    if (timeLeft === 0 && timerState === 'STARTED') {
      setProgress(100)
      setTimerState('FINISHED')
      onExpire()
    }
  }, [timeLeft, limit, start, onExpire, timerState, setTimerState])

  const startTimer = useCallback(() => {
    if (timerState === 'WAITING') {
      setTimerState('STARTED')
      start(limit * 1000)
    }
  }, [timerState, start, setTimerState, limit])

  const resetTimer = useCallback(
    (newLimit?: number) => {
      reset()
      start((newLimit || limit) * 1000)
      setTimerState('STARTED')
    },
    [start, limit, reset]
  )

  const pauseTimer = useCallback(() => {
    if (timerState === 'STARTED') {
      setTimerState('PAUSE')
      pause()
    }
  }, [timerState, pause])

  const minutes = Math.floor(timeLeft / 60000)
  const seconds = (timeLeft % 60000) / 1000

  type TimerUIProps = Pick<UIProps, 'width'>
  const Timer = (uiProps: TimerUIProps) => {
    const disabled = timerState !== 'STARTED'
    return (
      <UI
        strTime={formatTime(minutes, seconds)}
        progress={progress}
        disabled={disabled}
        width={uiProps.width}
      />
    )
  }

  return {
    Timer,
    pause: pauseTimer,
    state: timerState,
    reset: resetTimer,
    start: startTimer,
  }
}
