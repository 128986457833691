export const shuffle = <T>(ar: T[]) => ar.sort(() => Math.random() - 0.5)

export const calculateTime = (limit: number) => {
  const time = new Date()
  time.setSeconds(time.getSeconds() + limit)
  return time
}

export const paddingDigit = (num: number): string => {
  if (num < 10) return `0${num}`
  return `${num}`
}

export const formatTime = (mins: number, secs: number): string => {
  return paddingDigit(mins) + ':' + paddingDigit(secs)
}

export const renderAnswer =
  (title: string) =>
  (answer: Answer): string => {
    if (!answer) return title
    return title.replace('= ?', `= ${answer}`)
  }

export const generateQuestions =
  (questionMap: QuestionClass[]) =>
  (diff: number): QuestionData => {
    const maker = questionMap[diff - 1]
    const questions = maker.method(maker.params)
    const time = questions.length * maker.time
    return { questions, time, describe: maker.describe }
  }
