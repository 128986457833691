import React, { useState, useCallback, useEffect } from 'react'
import { Layout } from 'antd'
import { BrowserRouter } from 'react-router-dom'
import {
  QuestionList,
  TestOverlay,
  useTimer,
  Sidebar,
  AppHeader,
  NavBar,
} from 'components'
import { sideMenu, Categories } from 'libs'

const { Content } = Layout

type TEST_STATE = 'WAIT' | 'STARTED' | 'FINISHED'

function App() {
  const [testState, setTestState] = useState<TEST_STATE>('WAIT')
  const [score, setScore] = useState(0)
  const [difficulty, setDifficulty] = useState(1)
  const [category, setCategory] = useState<Category>('modulo')
  const [qs, setQs] = useState(Categories[category].data(difficulty))

  const { Timer, start, reset, pause } = useTimer(qs.time, () =>
    setTestState('FINISHED')
  )

  useEffect(() => {
    const newQuestionSet = Categories[category].data(difficulty)
    setQs(newQuestionSet)
  }, [difficulty, category])

  const retakeHandler = useCallback(() => {
    setScore(0)
    reset(qs.time)
    setTestState('STARTED')
  }, [reset, qs])

  const handleResultChange = useCallback(
    (newScore: number) => {
      setScore(newScore)
      if (newScore === qs.questions.length) {
        setTestState('FINISHED')
        pause()
      }
    },
    [qs.questions.length, pause]
  )

  const startTest = () => {
    setTestState('STARTED')
    start()
  }

  const handleChangeDifficulty = useCallback(
    (newDiff: number) => {
      if (newDiff <= Categories[category].numberOfLevels) {
        setDifficulty(newDiff)
      }
    },
    [category]
  )

  return (
    <BrowserRouter>
      <Layout>
        <Sidebar header={<AppHeader />} menu={sideMenu}>
          <div className="sidebar-timer">
            <Timer width={120} />
          </div>
        </Sidebar>
        <Layout>
          <NavBar
            onChangeCateogry={setCategory}
            category={category}
            lockCategory={testState === 'STARTED'}
            difficulty={difficulty}
            onChangeDifficulty={handleChangeDifficulty}
            lockDifficulty={testState === 'STARTED'}
          />
          <Content className="content-body">
            <div className="scroll-container">
              {testState === 'WAIT' && (
                <TestOverlay.Start onStart={startTest} />
              )}
              {testState === 'FINISHED' && (
                <TestOverlay.Finish
                  score={score}
                  totalScore={qs.questions.length}
                  onRetake={retakeHandler}
                />
              )}
              {testState === 'STARTED' && (
                <QuestionList
                  onResultChange={handleResultChange}
                  data={qs.questions}
                  questionPerRow={4}
                />
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </BrowserRouter>
  )
}

export default App
