import { CaretDownOutlined, LockOutlined } from '@ant-design/icons'
import { Button, Layout, Menu, Dropdown, Typography, Rate } from 'antd'
import { CategoryValues, Categories } from 'libs/constants'
import style from './NavBar.module.sass'

const { Header } = Layout

export type Props = {
  onChangeCateogry: (c: Category) => void
  lockCategory: boolean
  lockDifficulty: boolean
  difficulty: number
  onChangeDifficulty: (diff: number) => void
  category: Category
}

export function NavBar(props: Props) {
  const handleChangeCategory = (e: Record<string, any>) => {
    const key = e.key as Category
    props.onChangeCateogry(key)
  }

  const menu = (
    <Menu onClick={handleChangeCategory} className="category-menu">
      {CategoryValues.map(c => (
        <Menu.Item key={c} style={{ padding: 20 }}>
          <Typography.Title level={5}>{Categories[c].label}</Typography.Title>
        </Menu.Item>
      ))}
    </Menu>
  )

  const icon = props.lockCategory ? <LockOutlined /> : <CaretDownOutlined />

  return (
    <Header className={style.main_nav}>
      <div className={style.left}>
        <div>
          <b>Category</b>
          <Dropdown
            overlay={menu}
            disabled={props.lockCategory}
            placement="bottomLeft"
          >
            <Button type="text">
              {props.category.toUpperCase()} {icon}
            </Button>
          </Dropdown>
        </div>
        <div>
          <b className={style.nav_child}>Difficulty</b>
          <Rate
            className={style.rate_style}
            value={props.difficulty}
            onChange={props.onChangeDifficulty}
            allowClear={false}
            disabled={props.lockDifficulty}
          />
        </div>
      </div>
      <div>hello</div>
    </Header>
  )
}
